import useSWR from "swr";
import * as Icons from "@phosphor-icons/react";
import { URI_GET_STARTED, URI_TRAINING_V2, URL_TRAININGS_GROUPS } from "constants/urls";
import { useAuth } from "hooks/use-auth";
import { isLinkActive } from "utils/navigation";
import { IconsKey, SubNavGroup, TrainingGroup } from "lib/types";
import { GET_STARTED_ITEM } from "../constants";

export const useTrainingMenu = ({
    pathname,
    getStartedHidden,
    searchParams
}: {
    pathname: string;
    getStartedHidden?: boolean;
    searchParams: URLSearchParams | null;
}): SubNavGroup[] => {
    const page = searchParams?.get("page") ?? "";
    const { isFeatureEnabled } = useAuth();
    const isTrainingV3Enabled = isFeatureEnabled("training_v3");
    const isAllTrainingsActive = isLinkActive(URI_TRAINING_V2, pathname);

    const { data: trainingMenu } = useSWR<TrainingGroup[]>(
        (isTrainingV3Enabled && pathname.includes(URI_TRAINING_V2)) ||
            (getStartedHidden && pathname.includes(URI_GET_STARTED))
            ? URL_TRAININGS_GROUPS
            : null
    );
    const defaultTrainingMenu = {
        items: [
            {
                label: "All trainings",
                Icon: Icons.ListMagnifyingGlass,
                url: URI_TRAINING_V2,
                active: !page && isAllTrainingsActive
            }
        ]
    };
    const gettingStartedItem = {
        ...GET_STARTED_ITEM,
        hidden: !getStartedHidden,
        active: isLinkActive(URI_GET_STARTED, pathname)
    };

    if (!trainingMenu)
        return [
            {
                label: "Training library",
                items: [...defaultTrainingMenu.items, gettingStartedItem]
            }
        ];
    return [
        ...trainingMenu.map((menuItem, groupIndex) => ({
            label: menuItem.name,
            items: [
                ...menuItem.pages.map(({ name, icon, slug }) => ({
                    label: name,
                    Icon: Icons[icon as IconsKey] as Icons.Icon | undefined,
                    url: `${URI_TRAINING_V2}?page=${slug}`,
                    parameters: { page: slug },
                    active: isAllTrainingsActive && page === slug
                })),
                // Concatenates get started to the last group
                ...(groupIndex === trainingMenu.length - 1 ? [gettingStartedItem] : [])
            ]
        })),
        defaultTrainingMenu
    ];
};
